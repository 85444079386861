<template>
  <div class="navbar-container w-100 d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <template v-if="isNavMenuHidden">
      <ul :style="'border-right:1px solid ' + (isDark ? '#d0d2d6' : '#6e6b7b')" class="nav navbar-nav pr-1 mr-1"
          style="border-radius: 0">
        <li class="nav-item">
          <b-link v-show="show_project_list" v-b-toggle.project-list class="nav-link">
            <feather-icon icon="MenuIcon" size="21"/>
          </b-link>
          <b-link v-show="!show_project_list" v-b-toggle.app-sidebar-menu class="nav-link">
            <feather-icon icon="MenuIcon" size="21"/>
          </b-link>
        </li>
      </ul>
    </template>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <div class="w-100 h-100 d-flex position-relative">
        <div v-if="project_id===74" class="h-100 mr-50">
          <b-img src="/images/cabr-logo.png" style="width: 2.4rem;height: 2rem"></b-img>
        </div>
        <app-breadcrumb :isDark="isDark" class="h-100 mx-50"/>
      </div>

      <!-- Bookmarks Container -->
      <!-- <bookmarks />-->
      <!--	     <h2>{{$store.state.appConfig.app.appName}}</h2>-->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <app-store class="mr-50"/>
      <locale/>
      <theme-toggler class="mr-50"/>
      <dark-toggler class="mr-50"/>
      <user-dropdown/>
    </b-navbar-nav>
  </div>
</template>

<script>
// import Bookmarks from "./components/Bookmarks.vue";
import Locale from "./components/Locale.vue";
import SearchBar from "./components/SearchBar.vue";
import DarkToggler from "./components/DarkToggler.vue";
import ThemeToggler from "./components/ThemeToggler.vue";
import CartDropdown from "./components/CartDropdown.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
//引入应用商城
import AppStore from "@/views/layout/app-navbar/AppStore";
import useAppConfig from "@core/app-config/useAppConfig";
import {computed, onMounted, reactive, toRefs, watch} from "@vue/composition-api";
import store from "@/store";
import {routerParams} from "@/libs/utils/routerParams";

export default {
  components: {
    AppBreadcrumb,
    // Navbar Components
    // Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    ThemeToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    AppStore
  },

  props: {
    toggleSidebarMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  setup() {
    const {isNavMenuHidden, skin} = useAppConfig();
    const event = reactive({
      router: computed(() => {
        return store.state.menus.current.name;
      }),
      project_id: computed(() => {
        return parseInt(routerParams("project_id"));
      }),
      isDark: computed(() => {
        return skin === "dark";
      }),
      show_project_list: true,
    });

    watch(
        () => store.state.menus.current.name,
        (route) => {
          // console.log('show_project_list',((!route) || (route === 'index-project-list')))
          event.show_project_list = !route || route === "index-project-list";
        }
    );
    onMounted(() => {
      event.show_project_list = !event.router || event.router === "index-project-list";
    });
    return {
      ...toRefs(event),
      isNavMenuHidden,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/core/colors/palette-variables.scss";
</style>
