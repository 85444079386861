<template>
  <b-nav-item v-if="project_id!==74" @click="app_store_modal.show = true">
    <div class="d-flex align-items-center">
      <feather-icon
          icon="ShoppingCartIcon"
          :style="'color:' + (isDark ? '#d0d2d6' : '#5e5873')"
          size="21"
          class="mr-50"
      />
      <span
          :style="
                    'color:' +
                    ($store.state.appConfig.layout.skin === 'dark'
                        ? '#d0d2d6'
                        : '#5e5873')
                "
      >
                {{ $t("AppStore") }}
            </span>
    </div>
    <b-modal
        v-model="app_store_modal.show"
        scrollable
        :title="$t('AppStore')"
        hide-footer
        centered
        size="xl"
    >
      <div style="height: 80vh">
        <b-row class="my-2">
          <b-col cols="9">
            <b-button-group>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
              >
                <feather-icon icon="GridIcon" class="mr-50"/>
                全部
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
              >
                <feather-icon
                    icon="TerminalIcon"
                    class="mr-50"
                />
                算法
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
              >
                <feather-icon
                    icon="CommandIcon"
                    class="mr-50"
                />
                系统
              </b-button>
            </b-button-group>
          </b-col>
          <b-col cols="3">
            <!-- basic search -->
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon"/>
              </b-input-group-prepend>
              <b-form-input placeholder="搜索"/>
            </b-input-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
              cols="3"
              v-for="(item, itemIndex) in apps"
              :key="itemIndex"
          >
            <b-card>
              <b-card-text
                  class="
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
              >
                <b-avatar
                    rounded
                    :variant="
                                        item.category == '算法'
                                            ? 'light-danger'
                                            : 'light-warning'
                                    "
                    size="80"
                    class="mr-2"
                >
                  <feather-icon
                      :icon="
                                            item.category == '算法'
                                                ? 'TerminalIcon'
                                                : 'CommandIcon'
                                        "
                      size="50"
                  />
                </b-avatar>
                <div class="w-100">
                  <b-card-title class="text-truncate">
                    {{ item.title }}
                  </b-card-title>
                  <b-card-sub-title>
                    {{ item.subtitle }}
                  </b-card-sub-title>
                  <b-card-text class="mt-1">
                    <small
                        style="
                                                display: -webkit-box;
                                                -webkit-box-orient: vertical;
                                                -webkit-line-clamp: 2;
                                                overflow: hidden;
                                            "
                        class="text-secondary"
                    >{{ item.description }}
                    </small>
                  </b-card-text>

                  <!-- <hr style="margin-right: -1.5rem" /> -->
                  <b-badge
                      :variant="
                                            item.category == '算法'
                                                ? 'light-danger'
                                                : 'light-warning'
                                        "
                      class="mr-50"
                  >
                    <feather-icon
                        icon="TagIcon"
                        class="mr-25"
                    />
                    <span>{{ item.category }}</span>
                  </b-badge>

                  <b-badge
                      :variant="
                                            item.status == '上线'
                                                ? 'light-success'
                                                : 'light-secondary'
                                        "
                  >
                    <feather-icon
                        icon="CheckCircleIcon"
                        class="mr-25"
                    />
                    <span>{{ item.status }}</span>
                  </b-badge>

                  <b-button
                      size="sm"
                      href="#"
                      variant="flat-primary"
                      :href="item.src"
                      target="_blank"
                      class="float-right"
                  >
                    进入
                    <feather-icon
                        icon="ArrowRightCircleIcon"
                    />
                  </b-button>
                </div>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </b-nav-item>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import {computed, reactive, toRefs} from "@vue/composition-api";
import {BNavItem} from "bootstrap-vue";
import {routerParams} from "@/libs/utils/routerParams";

export default {
  name: "AppStore",
  components: {
    BNavItem,
  },
  setup() {
    const {skin} = useAppConfig();

    const isDark = computed(() => skin.value === "dark");
    const event = reactive({
      app_store_modal: {
        show: false,
      },
      project_id: computed(() => {
        return parseInt(routerParams('project_id'))
      }),
      apps: [
        {
          title: "中国近零能耗建筑在线案例集",
          subtitle: "中国建筑科学研究院",
          category: "系统",
          status: "上线",
          image: "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/app-store/cqc.png",
          description: "中国建筑科学研究院中国近零能耗建筑在线案例集",
          src: "http://www.nzebs.cn/",
        },
        {
          title: "负荷预测",
          subtitle: "中国建筑科学研究院",
          category: "算法",
          status: "上线",
          image: "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/app-store/cqc.png",
          description: "中国建筑科学研究院负荷预测",
          src: "",
        },
        {
          title: "健康建筑可持续运行认证应用系统",
          subtitle: "中国质量认证中心",
          category: "系统",
          status: "上线",
          image: "/images/features/cqc.jpg",
          description:
              "《健康建筑可持续评价技术规范》线上管理系统，为中国质量认证中心、参与认证的建筑企业、检测机构、评审专家提供一个线上申请、线上评审、持续监测的互联网平台。",
          src: "http://cqc.mtec.cc/",
        },
        {
          title: "监测计分及赛事管理系统",
          subtitle: "中国国际太阳能十项全能竞赛",
          category: "系统",
          status: "下架",
          image: "/images/features/sdc.jpg",
          description:
              "中国国际太阳能十项全能竞赛官方计分系统，对参赛作品能源、环境等竞赛数据进行实时监测和统计并实时计算和发布得分和排名。",
          src: "",
        },
        {
          title: "多联机节能量检测",
          subtitle: "美的集团股份有限公司",
          category: "算法",
          status: "上线",
          image: "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/app-store/cqc.png",
          description: "Midea多联机节能量检测",
          src: "",
        },
      ],
    });

    return {
      skin,
      isDark,
      ...toRefs(event),
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep.modal-lg {
  min-width: 800px !important;
}
</style>
