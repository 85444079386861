var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100 w-100"},[_c('div',{staticClass:"w-100 position-absolute",staticStyle:{"z-index":"-1","margin-left":"-2rem","margin-top":"-1.5rem","height":"calc(100vh - 4.45rem)"},style:('background: url(' + _vm.imgUrl + ') center center / cover;')}),_c('div',{staticClass:"w-100 d-flex justify-content-center"},[_c('div',{staticClass:"position-relative",staticStyle:{"width":"30%","min-width":"36rem"}},[_c('b-card',{staticClass:"font-text text-center jsf-header-title"},[_vm._v("锦什坊街叁拾伍号 ")])],1)]),_c('div',{staticClass:"mt-3"},[_c('div',{staticStyle:{"width":"25%","min-width":"28rem","position":"absolute","left":"2rem"}},[_c('b-card',{staticClass:"px-25 pb-2 jsf-card-linear-left",staticStyle:{"height":"66vh","overflow-y":"scroll"}},[_c('b-card-text',{staticClass:"h-100 d-flex flex-column justify-content-between"},[_c('b-tabs',{staticClass:"jsf-tabs"},_vm._l((_vm.subSystemTabs),function(subSystem,subSystemKey){return _c('b-tab',{key:'sub_system_tabs_' + subSystemKey,attrs:{"title":subSystem['title']}},[_c('b-row',{staticClass:"h-100"},[_c('b-col',{staticClass:"d-flex flex-column justify-content-between",staticStyle:{"height":"calc(65vh - 6.5rem)"},attrs:{"cols":"6"}},[_c('b-card-text',{staticClass:"h-100 d-flex flex-column justify-content-between"},_vm._l((subSystem.list_1),function(item,index){return _c('b-button',{key:index,ref:item.key + '_enter_button',refInFor:true,staticClass:"w-100 font-text text-left d-flex align-items-center jsf-button-linear",staticStyle:{"min-width":"150px"},attrs:{"to":{
                        name: 'project-jsf-subsystems',
                        params: {
                          project_id: 72,
                          system_key: item.key,
                          project_name:
                            _vm.$store.state.projects.selected.project_name,
                          title: item.name,
                          icon: item.icon,
                        },
                      },"variant":"outline-primary"}},[_c('b-avatar',{staticClass:"mr-1 jsf-avatar",attrs:{"size":"4rem","variant":"light-primary"}},[_c('i',{staticClass:"iconfont text-primary jsf-primary",class:item.icon,staticStyle:{"font-weight":"300"},style:({
                            'font-size': item.size
                              ? item.size + 'rem'
                              : '3rem',
                          })})]),_c('span',{staticClass:"text-primary jsf-primary",staticStyle:{"font-size":"1.2rem","line-height":"1.5rem"}},[_vm._v(_vm._s(item.name))])],1)}),1)],1),_c('b-col',{staticClass:"d-flex flex-column justify-content-between",staticStyle:{"height":"calc(65vh - 6.5rem)"},attrs:{"cols":"6"}},_vm._l((subSystem.list_2),function(item,index){return _c('b-button',{key:index,ref:item.key + '_enter_button',refInFor:true,staticClass:"w-100 font-text text-left d-flex align-items-center jsf-button-linear",staticStyle:{"min-width":"150px"},attrs:{"to":{
                      name: 'project-jsf-subsystems',
                      params: {
                        project_id: 72,
                        system_key: item.key,
                        project_name:
                          _vm.$store.state.projects.selected.project_name,
                        title: item.name,
                        icon: item.icon,
                      },
                    },"variant":"outline-primary"}},[_c('b-avatar',{staticClass:"mr-1 jsf-avatar",attrs:{"size":"4rem","variant":"light-primary"}},[_c('i',{staticClass:"iconfont text-primary jsf-primary",class:item.icon,staticStyle:{"font-weight":"300"},style:({
                          'font-size': item.size ? item.size + 'rem' : '3rem',
                        })})]),_c('span',{staticClass:"text-primary jsf-primary",staticStyle:{"font-size":"1.2rem","line-height":"1.5rem"}},[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1)}),1)],1)],1)],1),_c('div',{staticStyle:{"width":"25%","min-width":"28rem","position":"absolute","right":"2rem"}},[_c('b-card',{staticClass:"px-25 pb-2 jsf-card-linear-right",staticStyle:{"height":"66vh","overflow-y":"scroll"}},[_c('b-card-text',{staticClass:"h-100 d-flex flex-column justify-content-between"},[_c('b-tabs',{staticClass:"jsf-tabs"},_vm._l((_vm.alarmList),function(item,key){return _c('b-tab',{key:'alarm_type_' + key,attrs:{"title":item['title']}},[_c('div',{staticClass:"jsf35-homepage-scroll-notice",staticStyle:{"height":"calc(65vh - 20rem)","overflow-y":"scroll"},on:{"mouseenter":function($event){return _vm.scroll_stop('.jsf35-homepage-scroll-notice')},"mouseleave":function($event){return _vm.scroll_active('.jsf35-homepage-scroll-notice')}}},[(item['list'] && item['list'].length > 0)?_c('app-timeline',{staticClass:"mt-1 jsf-timeline"},_vm._l((item['list']),function(alarmItem,alarmItemIndex){return _c('app-timeline-item',{key:'alarm_item_' + alarmItemIndex,staticClass:"pb-1 text-shadow",staticStyle:{"cursor":"pointer"},attrs:{"subtitle":alarmItem['text'],"time":alarmItem['time'],"title":alarmItem['title'],"variant":"warning"},on:{"click":function($event){return _vm.alarmToSubSystemPage(alarmItem['title'])}}})}),1):_c('h6',{staticClass:"text-center mt-5"},[_vm._v("暂无报警信息")])],1)])}),1),_c('b-row',{staticClass:"mt-1"},_vm._l((_vm.subPages),function(subPage,subPageIndex){return _c('b-col',{key:subPageIndex,attrs:{"cols":"6"}},[_c('b-button',{ref:subPage.route + '_enter_button',refInFor:true,staticClass:"w-100 font-text text-left d-flex align-items-center jsf-button-linear",class:subPageIndex < 2 ? 'mb-1' : '',staticStyle:{"min-width":"150px"},attrs:{"to":{
                  name: subPage.route,
                  params: {
                    project_id: 72,
                    project_name: _vm.$store.state.projects.selected.project_name,
                    title: subPage.title,
                    icon: subPage.icon,
                  },
                },"variant":"outline-primary"}},[_c('b-avatar',{staticClass:"mr-1 jsf-avatar",attrs:{"size":"4rem","variant":"light-primary"}},[_c('feather-icon',{staticClass:"jsf-primary",attrs:{"icon":subPage.icon,"size":"35"}})],1),_c('span',{staticClass:"jsf-primary",staticStyle:{"font-size":"1.2rem","line-height":"1.5rem"}},[_vm._v(_vm._s(subPage.title))])],1)],1)}),1)],1)],1)],1)]),_c('div',{staticClass:"position-fixed",staticStyle:{"width":"100%","margin-left":"-2rem","padding":"0 2rem","bottom":"2rem"}},[_c('b-row',_vm._l((_vm.statisticsNumber),function(item,key){return _c('b-col',{key:key,attrs:{"cols":"2"}},[_c('b-card',{staticClass:"mb-1 jsf-card-linear-bottom cursor-pointer",staticStyle:{"height":"14vh","overflow-y":"scroll"},on:{"click":function($event){return _vm.showDetailIframe(item)}}},[_c('b-card-text',{staticClass:"d-flex flex-column h-100 justify-content-between"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center px-1",staticStyle:{"flex":"1"}},[_c('b-avatar',{staticClass:"jsf-avatar",attrs:{"variant":'light-' + item.color,"size":"5rem"}},[_c('i',{staticClass:"iconfont jsf-secondary-red",class:[
                    item.color ? ("text-" + (item.color)) : 'avatar-color',
                    item.icon ],staticStyle:{"font-weight":"300"},style:({
                    'font-size': item.size ? item.size + 'rem' : '3.5rem',
                  })})]),_c('div',{staticClass:"font-digit text-right jsf-primary",staticStyle:{"font-size":"2.5rem","flex":"1"}},[_c('h4',{staticClass:"font-text jsf-grey-lighten mb-1",class:item.color ? ("text-" + (item.color)) : 'avatar-color'},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',[_vm._v(_vm._s(item.value))])])],1)])],1)],1)}),1)],1),_c('b-modal',{attrs:{"size":"lg","centered":"","hide-footer":"","title":_vm.detailIframeModalShow.title},model:{value:(_vm.detailIframeModalShow.show),callback:function ($$v) {_vm.$set(_vm.detailIframeModalShow, "show", $$v)},expression:"detailIframeModalShow.show"}},[_c('b-embed',{attrs:{"id":"jsf-homepage-detail-iframe","src":_vm.detailIframeModalShow.src,"allow-cross-origin":"","allowfullscreen":"","loading":"lazy","type":"iframe"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }