<template>
  <div class="h-100 w-100">
    <!-- background -->
    <div
      :style="'background: url(' + imgUrl + ') center center / cover;'"
      class="w-100 position-absolute"
      style="
        z-index: -1;
        margin-left: -2rem;
        margin-top: -1.5rem;
        height: calc(100vh - 4.45rem);
      "
    ></div>

    <!-- top -->
    <div class="w-100 d-flex justify-content-center">
      <div class="position-relative" style="width: 30%; min-width: 36rem">
        <b-card class="font-text text-center jsf-header-title"
          >锦什坊街叁拾伍号
        </b-card>
      </div>
    </div>

    <div class="mt-3">
      <!-- left -->
      <div style="width: 25%; min-width: 28rem; position: absolute; left: 2rem">
        <b-card
          class="px-25 pb-2 jsf-card-linear-left"
          style="height: 66vh; overflow-y: scroll"
        >
          <b-card-text class="h-100 d-flex flex-column justify-content-between">
            <b-tabs class="jsf-tabs">
              <b-tab
                v-for="(subSystem, subSystemKey) in subSystemTabs"
                :key="'sub_system_tabs_' + subSystemKey"
                :title="subSystem['title']"
              >
                <b-row class="h-100">
                  <b-col
                    class="d-flex flex-column justify-content-between"
                    cols="6"
                    style="height: calc(65vh - 6.5rem)"
                  >
                    <b-card-text
                      class="h-100 d-flex flex-column justify-content-between"
                    >
                      <b-button
                        v-for="(item, index) in subSystem.list_1"
                        :ref="item.key + '_enter_button'"
                        :key="index"
                        :to="{
                          name: 'project-jsf-subsystems',
                          params: {
                            project_id: 72,
                            system_key: item.key,
                            project_name:
                              $store.state.projects.selected.project_name,
                            title: item.name,
                            icon: item.icon,
                          },
                        }"
                        class="w-100 font-text text-left d-flex align-items-center jsf-button-linear"
                        style="min-width: 150px"
                        variant="outline-primary"
                      >
                        <b-avatar
                          class="mr-1 jsf-avatar"
                          size="4rem"
                          variant="light-primary"
                        >
                          <i
                            :class="item.icon"
                            :style="{
                              'font-size': item.size
                                ? item.size + 'rem'
                                : '3rem',
                            }"
                            class="iconfont text-primary jsf-primary"
                            style="font-weight: 300"
                          ></i>
                        </b-avatar>
                        <span
                          class="text-primary jsf-primary"
                          style="font-size: 1.2rem; line-height: 1.5rem"
                          >{{ item.name }}</span
                        >
                      </b-button>
                    </b-card-text>
                  </b-col>

                  <b-col
                    class="d-flex flex-column justify-content-between"
                    cols="6"
                    style="height: calc(65vh - 6.5rem)"
                  >
                    <b-button
                      :ref="item.key + '_enter_button'"
                      v-for="(item, index) in subSystem.list_2"
                      :key="index"
                      :to="{
                        name: 'project-jsf-subsystems',
                        params: {
                          project_id: 72,
                          system_key: item.key,
                          project_name:
                            $store.state.projects.selected.project_name,
                          title: item.name,
                          icon: item.icon,
                        },
                      }"
                      class="w-100 font-text text-left d-flex align-items-center jsf-button-linear"
                      style="min-width: 150px"
                      variant="outline-primary"
                    >
                      <b-avatar
                        class="mr-1 jsf-avatar"
                        size="4rem"
                        variant="light-primary"
                      >
                        <i
                          :class="item.icon"
                          :style="{
                            'font-size': item.size ? item.size + 'rem' : '3rem',
                          }"
                          class="iconfont text-primary jsf-primary"
                          style="font-weight: 300"
                        ></i>
                      </b-avatar>
                      <span
                        class="text-primary jsf-primary"
                        style="font-size: 1.2rem; line-height: 1.5rem"
                        >{{ item.name }}</span
                      >
                    </b-button>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-card-text>
        </b-card>
      </div>

      <!-- right -->
      <div
        style="width: 25%; min-width: 28rem; position: absolute; right: 2rem"
      >
        <b-card
          class="px-25 pb-2 jsf-card-linear-right"
          style="height: 66vh; overflow-y: scroll"
        >
          <b-card-text class="h-100 d-flex flex-column justify-content-between">
            <b-tabs class="jsf-tabs">
              <b-tab
                v-for="(item, key) in alarmList"
                :key="'alarm_type_' + key"
                :title="item['title']"
              >
                <div
                  class="jsf35-homepage-scroll-notice"
                  style="height: calc(65vh - 20rem); overflow-y: scroll"
                  @mouseenter="scroll_stop('.jsf35-homepage-scroll-notice')"
                  @mouseleave="scroll_active('.jsf35-homepage-scroll-notice')"
                >
                  <app-timeline
                    v-if="item['list'] && item['list'].length > 0"
                    class="mt-1 jsf-timeline"
                  >
                    <app-timeline-item
                      v-for="(alarmItem, alarmItemIndex) in item['list']"
                      :key="'alarm_item_' + alarmItemIndex"
                      :subtitle="alarmItem['text']"
                      :time="alarmItem['time']"
                      :title="alarmItem['title']"
                      class="pb-1 text-shadow"
                      variant="warning"
                      @click="alarmToSubSystemPage(alarmItem['title'])"
                      style="cursor: pointer"
                    />
                  </app-timeline>
                  <h6 v-else class="text-center mt-5">暂无报警信息</h6>
                </div>
              </b-tab>
            </b-tabs>

            <b-row class="mt-1">
              <b-col
                v-for="(subPage, subPageIndex) in subPages"
                :key="subPageIndex"
                cols="6"
              >
                <b-button
                  :ref="subPage.route + '_enter_button'"
                  :class="subPageIndex < 2 ? 'mb-1' : ''"
                  :to="{
                    name: subPage.route,
                    params: {
                      project_id: 72,
                      project_name: $store.state.projects.selected.project_name,
                      title: subPage.title,
                      icon: subPage.icon,
                    },
                  }"
                  class="w-100 font-text text-left d-flex align-items-center jsf-button-linear"
                  style="min-width: 150px"
                  variant="outline-primary"
                >
                  <b-avatar
                    class="mr-1 jsf-avatar"
                    size="4rem"
                    variant="light-primary"
                  >
                    <feather-icon
                      :icon="subPage.icon"
                      class="jsf-primary"
                      size="35"
                    />
                  </b-avatar>
                  <span
                    class="jsf-primary"
                    style="font-size: 1.2rem; line-height: 1.5rem"
                    >{{ subPage.title }}</span
                  >
                </b-button>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </div>
    </div>

    <!-- bottom -->
    <div
      class="position-fixed"
      style="width: 100%; margin-left: -2rem; padding: 0 2rem; bottom: 2rem"
    >
      <b-row>
        <b-col v-for="(item, key) in statisticsNumber" :key="key" cols="2">
          <b-card
            class="mb-1 jsf-card-linear-bottom cursor-pointer"
            style="height: 14vh; overflow-y: scroll"
            @click="showDetailIframe(item)"
          >
            <b-card-text
              class="d-flex flex-column h-100 justify-content-between"
            >
              <div
                class="d-flex justify-content-between align-items-center px-1"
                style="flex: 1"
              >
                <b-avatar
                  :variant="'light-' + item.color"
                  class="jsf-avatar"
                  size="5rem"
                >
                  <i
                    :class="[
                      item.color ? `text-${item.color}` : 'avatar-color',
                      item.icon,
                    ]"
                    :style="{
                      'font-size': item.size ? item.size + 'rem' : '3.5rem',
                    }"
                    class="iconfont jsf-secondary-red"
                    style="font-weight: 300"
                  ></i>
                </b-avatar>
                <div
                  class="font-digit text-right jsf-primary"
                  style="font-size: 2.5rem; flex: 1"
                >
                  <h4
                    :class="item.color ? `text-${item.color}` : 'avatar-color'"
                    class="font-text jsf-grey-lighten mb-1"
                  >
                    {{ item.name }}
                  </h4>
                  <span>{{ item.value }}</span>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <!-- bottom modal -->
    <b-modal
      v-model="detailIframeModalShow.show"
      size="lg"
      centered
      hide-footer
      :title="detailIframeModalShow.title"
    >
      <b-embed
        id="jsf-homepage-detail-iframe"
        :src="detailIframeModalShow.src"
        allow-cross-origin
        allowfullscreen
        loading="lazy"
        type="iframe"
      ></b-embed>
    </b-modal>
  </div>
</template>

<script>
import store from "@/store";
import { showToast } from "@/libs/utils/showToast";
import {
  computed,
  nextTick,
  onMounted,
  reactive,
  toRefs,
} from "@vue/composition-api";
import { momentJs } from "@/libs/utils/moment";
import { routerParams } from "@/libs/utils/routerParams";
import Loading from "../subsystems/layout/components/ModelLoading";
import { scroll_active, scroll_stop } from "@/libs/utils/autoScroll";
import AppNavbarVerticalLayout from "@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import axios from "axios";

export default {
  name: "ProjectDetail",
  components: {
    AppNavbarVerticalLayout,
    Loading,
    AppTimeline,
    AppTimelineItem,
  },
  setup(props, { refs }) {
    const event = reactive({
      project_id: computed(() => {
        return routerParams("project_id");
      }),
      projectInfo: {
        images: [],
      },
      projectEnergy: {},
      projectParams: {
        current: {},
        list: {},
      },
      projectOperation: {
        alarm: [],
        inspection: [],
        repair: [],
        agent: [],
      },
      projectOptimization: {
        energy_list: [],
        prediction_list: [],
      },
      //左侧子系统列表
      subSystemTabs: [
        {
          title: "常用系统",
          list_1: [
            {
              name: "空调通风",
              color: "primary",
              icon: "icon-fengsu",
              key: "air-conditioning-ventilation",
            },
            {
              name: "制冷系统",
              icon: "icon-xiaxue-xuehua",
              color: "primary",
              key: "cold-source",
              size: 3.8,
            },
            {
              name: "变配电",
              icon: "icon-shandian",
              color: "primary",
              key: "electrical-substation",
            },
            {
              name: "能源管理",
              color: "primary",
              icon: "icon-nengyuanguanli",
              key: "energy-management",
            },
            {
              name: "公共照明",
              color: "primary",
              icon: "icon-deng1",
              key: "intelligent-lighting",
            },
            {
              name: "安防系统",
              color: "primary",
              icon: "icon-shield-cross-line",
              key: "intelligent-security",
            },
          ],
          list_2: [
            {
              name: "给排水",
              icon: "icon-kongqishidu",
              color: "primary",
              key: "water-supply-drainage",
              size: 3.8,
            },
            {
              name: "供热系统",
              color: "primary",
              icon: "icon-xiaofang",
              key: "heat-source",
            },
            {
              name: "电梯系统",
              color: "primary",
              icon: "icon-xiaofangdianti",
              key: "intelligent-elevator",
            },
            {
              name: "环境监测",
              color: "primary",
              icon: "icon-kongqiwendu-copy",
              key: "intelligent-environment",
              size: 3.8,
            },

            {
              name: "泛光照明",
              icon: "icon-deng",
              color: "primary",
              key: "floodlighting",
            },

            {
              name: "消防系统",
              color: "primary",
              icon: "icon-xiaofangguanli",
              key: "intelligent-fire-fighting",
            },
          ],
        },
        {
          title: "其他系统",
          list_1: [
            {
              name: "应急疏散",
              icon: "icon-alarm-warning-line",
              color: "primary",
              key: "emergency-evacuation",
            },
          ],
          list_2: [
            {
              name: "隐蔽工程",
              color: "primary",
              icon: "icon-sound-module-line",
              key: "hidden-construction-work",
            },
            /* {
                                        name: "水浸系统",
                                        color: "primary",
                                        icon: "icon-sound-module-line",
                                        key: "water-alarm",
                                    }, */
          ],
        },
      ],
      //右侧工程入口
      subPages: [
        {
          title: "运营中心",
          icon: "ApertureIcon",
          route: "project-jsf-operations-center",
        },

        {
          title: "工程中心",
          icon: "TrelloIcon",
          route: "project-jsf-engineering-center",
        },
        {
          title: "安保中心",
          icon: "ShieldIcon",
          route: "project-jsf-security-center",
        },
        {
          title: "服务中心",
          icon: "Share2Icon",
          route: "project-jsf-service-center",
        },
      ],
      //右侧报警信息
      alarmList: [
        { title: "设备报警", list: [] },
        { title: "消防报警", list: [] },
        { title: "安防报警", list: [] },
      ],
      //底部统计数据入口
      statisticsNumber: {
        alarm: {
          name: "设备报警",
          color: "danger",
          icon: "icon-alarm",
          size: 3.1,
          value: 0,
          iframe: "app.dreamsfount35.com/ems/alarmRecord/index",
        },
        fault: {
          name: "消防报警",
          color: "warning",
          icon: "icon-icon-kaiguzhangdan",
          size: 2.8,
          value: 0,
          iframe: "app.dreamsfount35.com/ems/fireAlarmHistoryLog/index",
        },
        notice: {
          name: "安防报警",
          color: "info",
          icon: "icon-tongzhi",
          size: 3.2,
          value: 0,
          iframe: "app.dreamsfount35.com/ems/securityAlarmHistoryLog/index",
        },
        plan: {
          name: "巡检数量",
          color: "success",
          icon: "icon-inspection",
          size: 3.7,
          value: 0,
          iframe: "app.dreamsfount35.com/ems/checkTask/index",
        },
        repair: {
          name: "报修数量",
          color: "warning",
          icon: "icon-baoxiu",
          value: 0,
          iframe: "app.dreamsfount35.com/ems/repairRequest/index",
        },
        complaint: {
          name: "投诉数量",
          color: "primary",
          icon: "icon-tousu",
          size: 2.8,
          value: 0,
          iframe: "app.dreamsfount35.com/ems/complaint/index",
        },
      },

      detailIframeModalShow: {
        show: false,
        title: "",
        src: "",
      },
    });

    //显示统计数据详情Iframe
    const showDetailIframe = (item) => {
      event.detailIframeModalShow.show = true;
      event.detailIframeModalShow.title = item.name;
      let iframeSrc = item.iframe;
      if (iframeSrc) {
        //若链接不以http开始，则加上当前网址的http协议
        const location_protocol = window.location.protocol;
        if (iframeSrc.slice(0, 4) !== "http") {
          iframeSrc = location_protocol + "//" + iframeSrc;
        }
        //对小程序链接单独处理
        if (iframeSrc.indexOf("v4.qiyebox.com") > -1) {
          axios
            .get("https://v4.qiyebox.com/admin/user/info/e9/token")
            .then((res) => {
              const tenant_id = res.data.data.tenant_id;
              const access_token = res.data.data.access_token;
              let symbol = iframeSrc.indexOf("?") > -1 ? "&" : "?";
              iframeSrc +=
                symbol +
                "tenant_id=" +
                tenant_id +
                "&access_token=" +
                access_token;
            });
        }
      }
      event.detailIframeModalShow.src = iframeSrc;
      if (iframeSrc) {
        load_iframe();
      } else if (iframeSrc === "") {
        showToast(null, "错误：", "此链接为空！");
      }
    };

    //加载iframe
    const load_iframe = () => {
      showToast(null, "提示：", "页面加载中...");
      const postData = {
        accessToken: localStorage.getItem("accessToken"),
        project_id: 72,
      };
      nextTick(() => {
        const iframe_dom = document.getElementById(
          "jsf-homepage-detail-iframe"
        );
        if (iframe_dom) {
          iframe_dom.onload = () => {
            iframe_dom.contentWindow.postMessage(postData, "*");
            // showToast(null, '成功：', '页面已加载！');
          };
        }
      });
    };

    const getProjectInfo = () => {
      const project_id = event.project_id;
      store
        .dispatch("projects/fetchProject", project_id)
        .then((response) => {
          event.projectInfo = response.data;
        })
        .catch((error) => {
          showToast(error);
        });
    };

    const getNumbers = () => {
      const project_id = event.project_id;
      store
        .dispatch("jsf35/fetchStatisticsNumbers", project_id)
        .then((response) => {
          let statisticsNumber = Object.assign({}, event.statisticsNumber);
          for (const statisticsNumberKey in statisticsNumber) {
            statisticsNumber[statisticsNumberKey]["value"] =
              response.data[statisticsNumberKey];
          }
          event.statisticsNumber = statisticsNumber;
        })
        .catch((error) => {
          showToast(error);
        });
    };

    const getAlarmList = () => {
      const project_id = event.project_id;
      store
        .dispatch("jsf35/fetchAlarms", project_id)
        .then((response) => {
          event.alarmList = response.data;
        })
        .catch((error) => {
          showToast(error);
        });
    };

    //根据报警信息跳转到对应子系统
    const alarmToSubSystemPage = (alarm_title) => {
      const subsystem_list = {
        "air-conditioning-ventilation": ["AHU"],
        "electrical-substation": ["相电"],
        "intelligent-fire-fighting": ["火灾"],
      };
      let key = "";
      for (const subsystemKey in subsystem_list) {
        const str_arr = subsystem_list[subsystemKey];
        for (const strItem of str_arr) {
          if (alarm_title.indexOf(strItem) > -1) {
            key = subsystemKey;
            break;
          }
        }
        if (key) {
          break;
        }
      }

      if (refs[key + "_enter_button"][0]) {
        refs[key + "_enter_button"][0].$el.click();
      }
    };

    onMounted(() => {
      getProjectInfo();
      getNumbers();
      getAlarmList();
      setTimeout(() => {
        scroll_active(".jsf35-homepage-scroll-notice");
      }, 2000);
    });
    return {
      ...toRefs(event),
      momentJs,
      alarmToSubSystemPage,
      scroll_active,
      scroll_stop,
      showDetailIframe,
    };
  },

  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg =
          "https://cabrenergy-v2-1255710621.file.myqcloud.com/projects/72_bg_homepage.png";
      } else {
        this.sideImg =
          "https://cabrenergy-v2-1255710621.file.myqcloud.com/projects/72_bg_1.png";
      }
      return this.sideImg;
    },
  },
};
</script>

<style lang="scss">
.navbar.header-navbar {
  border-bottom: 1px solid rgba(158, 158, 158, 0.25);
  background-image: linear-gradient(#f8f8f8, #fdfdfd) !important;
  //box-shadow: 0 0.25rem 1rem 0 #9e9e9e;

  .dark-layout & {
    border-bottom: 2px solid rgba(24, 144, 255, 0.25);
    background-image: linear-gradient(#161d31, #232b40) !important;
    //box-shadow: 0 0.25rem 1rem 0 #424242;
  }

  [data-theme="jsf_theme"] & {
    border-bottom: 1px solid rgba(255, 227, 188, 0.25);
    background-image: linear-gradient(#3f382f, #594f43) !important;

    //box-shadow: 0 0.25rem 1rem 0 #81725f;
    h3,
    a,
    span,
    p,
    svg,
    .icon {
      color: #9e9e9e !important;
      border-color: #9e9e9e !important;
      background-color: transparent !important;

      &.badge-success {
        background-color: #28c76f !important;
      }
    }

    ul.navbar-nav {
      border-color: #9e9e9e !important;
    }

    li::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='%23b4b7bd' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
    }
  }
}

[data-theme="jsf_theme"] {
  /* 组件 */
  #app-sidebar-menu {
    background: #81725f !important;
  }
}
</style>
<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}

::v-deep .modal-lg {
  max-width: 90vw !important;
}

/* ================================= 李立引入 ============================ */

/* 字体 */
.font-title {
  font-family: HYLingXin, sans-serif;
}

.font-text {
  font-family: HuXiaoBoNanShenTi, sans-serif;
}

.font-digit {
  font-family: LcdD, sans-serif;
}

[data-theme="jsf_theme"] {
  /* 主题色 */
  .jsf-primary {
    color: #ffe3bc !important;
  }

  .jsf-primary-darken {
    color: #3f382f !important;
  }

  .jsf-secondary-gold {
    color: #b98a06 !important;
  }

  .jsf-secondary-red {
    color: #a15d55 !important;
  }

  .jsf-bg {
    color: #f1daba !important;
  }

  .jsf-bg-lighten {
    color: #fbf3e8 !important;
  }

  .jsf-bg-darken {
    color: #81725f !important;
  }

  /* 灰度 */
  .jsf-grey {
    color: #9e9e9e !important;
  }

  .jsf-grey-lighten {
    color: #eeeeee !important;
  }

  .jsf-grey-darken {
    color: #424242 !important;
  }

  .jsf-avatar {
    background: rgba(63, 56, 47, 0.5) !important;
  }

  .jsf-button-outline {
    background-color: transparent !important;
    border-color: #c1ad8f !important;
    color: #c1ad8f !important;
  }
}

.jsf-header-title {
  position: absolute;
  margin-top: -5.95rem;
  z-index: 997;
  width: 100%;
  font-size: 2rem;
  letter-spacing: 0.5rem;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  box-shadow: none !important;

  border-bottom: 2px solid rgba(158, 158, 158, 0.5);
  background-image: linear-gradient(#f8f8f8, #ffffff) !important;

  .dark-layout & {
    border-bottom: 2px solid rgba(24, 144, 255, 0.5);
    background-image: linear-gradient(#161d31, #283046) !important;
  }

  [data-theme="jsf_theme"] & {
    color: #ffe3bc;
    border-bottom: 2px solid rgba(255, 227, 188, 0.5);
    background-image: linear-gradient(#3f382f, #63584a) !important;
  }
}

.jsf-card-linear-left {
  background-color: transparent !important;
  background-image: linear-gradient(
    to right,
    rgba(248, 248, 248, 0.75),
    rgba(255, 255, 255, 0.1)
  ) !important;

  .dark-layout & {
    background-image: linear-gradient(
      to right,
      rgba(40, 48, 70, 0.75),
      rgba(22, 29, 49, 0.1)
    ) !important;
  }

  [data-theme="jsf_theme"] & {
    background-image: linear-gradient(
      to right,
      rgba(63, 56, 47, 0.75),
      rgba(255, 227, 188, 0.1)
    ) !important;
  }
}

.jsf-card-linear-right {
  background-color: transparent !important;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.1),
    rgba(248, 248, 248, 0.75)
  ) !important;

  .dark-layout & {
    background-image: linear-gradient(
      to right,
      rgba(22, 29, 49, 0.1),
      rgba(40, 48, 70, 0.75)
    ) !important;
  }

  [data-theme="jsf_theme"] & {
    background-image: linear-gradient(
      to right,
      rgba(255, 227, 188, 0.1),
      rgba(63, 56, 47, 0.75)
    ) !important;
  }
}

.jsf-card-linear-bottom {
  background-color: transparent !important;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.1),
    rgba(248, 248, 248, 0.75)
  ) !important;

  .dark-layout & {
    background-image: linear-gradient(
      rgba(22, 29, 49, 0.1),
      rgba(40, 48, 70, 0.75)
    ) !important;
  }

  [data-theme="jsf_theme"] & {
    background-image: linear-gradient(
      rgba(255, 227, 188, 0.1),
      rgba(63, 56, 47, 0.75)
    ) !important;
  }
}

.jsf-button-linear {
  background-color: transparent !important;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.25),
    rgba(248, 248, 248, 0.75)
  ) !important;

  .dark-layout & {
    background-image: linear-gradient(
      rgba(22, 29, 49, 0.25),
      rgba(40, 48, 70, 0.75)
    ) !important;
  }

  [data-theme="jsf_theme"] & {
    color: #ffe3bc !important;
    border: 1px solid #9e9e9e !important;
    background-image: linear-gradient(
      rgba(255, 227, 188, 0.25),
      rgba(63, 56, 47, 0.75)
    ) !important;
  }
}

.jsf-button-linear:hover {
  box-shadow: 0 0 2rem 0 rgba(248, 248, 248, 1) !important;

  .dark-layout & {
    box-shadow: 0 0 2rem 0 rgba(22, 29, 49, 1) !important;
  }

  [data-theme="jsf_theme"] & {
    border: 1px solid #ffe3bc !important;
    box-shadow: 0 0 2rem 0 #ffe3bc !important;
    color: #ffe3bc !important;
  }
}

.jsf-button {
  [data-theme="jsf_theme"] & {
    background-color: #c1ad8f !important;
    border-color: #c1ad8f !important;
  }
}

.jsf-tabs ::v-deep {
  .nav-item .nav-link {
    &.active {
      background-color: transparent !important;

      [data-theme="jsf_theme"] & {
        color: #ffe3bc !important;
      }
    }

    &:after {
      [data-theme="jsf_theme"] & {
        background: #ffe3bc !important;
      }
    }
  }

  p,
  h6,
  h5,
  label,
  small {
    color: #6e6b7b !important;

    [data-theme="jsf_theme"] &,
    .dark-layout & {
      color: #d0d2d6 !important;
    }
  }
}
</style>
